import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import {
    getFileFormat,
    getFileIcon,
    getFileIconColor,
    getUrl,
    isImageFile,
    isMarkdownFile,
} from '../utils';
import { developerMode } from '../Constants';
import { Button, Modal, Dropdown, ConfirmModal } from './reusableComponents';
import {
    faArrowUpRightFromSquare,
    faBugSlash,
    faComments,
    faFile,
    faTrash,
    faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { useGlobalCredits } from './GlobalStateContext';
import { DebugViewerContainer } from './debugViewerContainer';
import { RepoType } from '../constants/repoType';
import axios from 'axios';
import { message } from 'antd';

export const FilesListView = ({
    repo,
    allReposFilesData,
    setWorkOnRepo,
    repoId,
    email,
    chatrepofunction,
    folderName,
    workOnRepo,
    mode,
    fileToChat,
    setFileToChat,
    isAdmin,
    closeSidebar,
    togglePdfVisibility,
    getRepoFilesData,
    setIsLoading,
}) => {
    const [showDebugModal, setShowDebugModal] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [closeDropdownSignal, setCloseDropdownSignal] = useState(false);
    const { transactionMode, setTransactionMode } = useGlobalCredits();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [urlFileMap, setUrlFileMap] = useState({});

    const BREAKPOINT_WIDTH = 1024;

    const repoFileData =
        allReposFilesData && allReposFilesData.size
            ? allReposFilesData.get(repoId)
            : null;

    const extension = fileName ? getFileFormat(fileName) : null;
    const isImage = extension ? isImageFile(extension) : false;

    const fetchUrlInfo = async (repoId) => {
        try {
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/crawl/url_file_map?repo_id=${repoId}`
            );
            const response = await axios.get(dynamicUrl);
            return response.data; // Assuming the response is a mapping of URLs
        } catch (error) {
            console.error('Error fetching URL info:', error);
            return {};
        }
    };

    useEffect(() => {
        const fetchUrls = async () => {
            // Create an object to hold all URL info from different repos

            // Fetch URL info for each repo individually
            const urls = await fetchUrlInfo(repoId);

            console.log('urls', urls);
            // Merge the results into the allUrlInfo object
            setUrlFileMap(urls);
        };

        if (repoId) {
            fetchUrls();
        }
    }, [repoId]);

    // Function to handle confirmed deletion
    const handleDeleteFile = async () => {
        if (!fileToDelete) return;

        try {
            setShowConfirmModal(false);
            setIsLoading(true);
            const deleteApiUrl = getUrl(
                `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/delete-file/`
            );

            const payload = {
                repo_id: repoId,
                file_name: fileToDelete.name,
                repo_name: repo.name,
                email: email,
                is_non_db_repo:
                    fileToDelete.repotype !== RepoType.SQLLITE &&
                    fileToDelete.repotype !== RepoType.POSTGRESS &&
                    fileToDelete.repotype !== RepoType.MSSQL,
            };

            await axios.post(deleteApiUrl, payload);
            message.success('File deleted successfully', 2);
            setIsLoading(false);
            // Refresh the file list after deletion
            await getRepoFilesData(repoId);
        } catch (error) {
            console.error('Error deleting file:', error);
            message.error('Failed to delete the file', 2);
        } finally {
            setFileToDelete(null);
        }
    };

    if (repoFileData) {
        const isExpanded = repoFileData.isExpanded;
        const filesData = repoFileData.filesData;

        const list = filesData.map((item, index) => {
            const extension = getFileFormat(item.name);
            const isImage = isImageFile(extension);

            // Get the URL for the current file
            console.log('item', item);
            const fileUrl = urlFileMap[item.name]; // Assuming urlFileMap contains the mapping

            console.log('fileurl', fileUrl);
            // Define dropdown options dynamically
            let dropDownList = [];

            if (developerMode && isAdmin && !isImage) {
                dropDownList.push({
                    label: 'Debug File',
                    icon: faBugSlash,
                    iconColor: 'blue',
                    action: () => {
                        setFileName(item.name);
                        setShowDebugModal(true);
                    },
                });
            } else {
                dropDownList.push({
                    label: 'View File',
                    icon: faArrowUpRightFromSquare,
                    iconColor: 'green',
                    action: () => {
                        setFileName(item.name);
                        setShowDebugModal(true);
                    },
                });
            }

            if (!(item.name.endsWith('.db') || mode === 'info')) {
                dropDownList.push({
                    label: 'Chat with file',
                    icon: faComments,
                    iconColor: 'purple',
                    action: () => {
                        if (transactionMode) {
                            setTransactionMode(!transactionMode);
                        }
                        setFileToChat(item.name);
                        setWorkOnRepo(repoId);
                        chatrepofunction(repo);
                        if (window.innerWidth < BREAKPOINT_WIDTH) {
                            togglePdfVisibility('reset');
                            closeSidebar();
                        }
                    },
                });
            }

            // Delete action
            dropDownList.push({
                label: 'Delete File',
                icon: faTrash,
                iconColor: 'red',
                action: () => {
                    setFileToDelete(item); // Store file details for deletion
                    setShowConfirmModal(true); // Show confirmation modal
                },
            });

            return (
                <div
                    key={`${item.document_id} ${index}`}
                    className='flex justify-between items-center'
                >
                    <div className='truncate pt-5 border-l-2 pr-2 flex items-center'>
                        <span
                            className='mr-2 font-bold'
                            style={{ color: '#e6e6e8' }}
                        >
                            -
                        </span>
                        <FontAwesomeIcon
                            icon={getFileIcon(item.name)}
                            color={getFileIconColor(item.name)}
                            className='mr-2 drop-shadow-sm'
                        />
                        <span
                            className='truncate text-base'
                            title={fileUrl} // Show URL on hover
                        >
                            {item.name}
                        </span>
                    </div>
                    <div className='pt-3 mt-2'>
                        {item.name.endsWith('.db') ? null : (
                            <Dropdown
                                dropDownList={dropDownList}
                                icon={<FontAwesomeIcon icon={faEllipsisV} />}
                                showButtonText={false}
                                buttonText={null}
                                buttonHoverText={'More File Actions'}
                                additionalButtonAction={() => {
                                    setCloseDropdownSignal(false);
                                }}
                                titleText={'File Actions'}
                                buttonClassName={'px-3.5 py-2 ml-2'}
                                backgroundColor={'white'}
                                textColor={'green'}
                                placement={'right'}
                                closeSignal={closeDropdownSignal}
                            />
                        )}
                    </div>
                </div>
            );
        });

        const extension = getFileFormat(fileName);
        const isImage = isImageFile(extension);
        const isMarkdown = isMarkdownFile(extension);

        if (isExpanded) {
            return (
                <div>
                    <div
                        className={`${
                            workOnRepo === repoId
                                ? 'bg-backgrounds-primary100'
                                : 'bg-backgrounds-white'
                        } rounded-b-xl pl-4 pr-2 pb-3 truncate shadow-sm border`}
                    >
                        {list}
                    </div>
                    {showConfirmModal && (
                        <ConfirmModal
                            modalText={`Are you sure you want to delete "${fileToDelete?.name}"?`}
                            onConfirm={handleDeleteFile}
                            onCancel={() => setShowConfirmModal(false)}
                        />
                    )}
                    {/* Debug Modal */}
                    {showDebugModal && (
                        <Modal
                            title={
                                isAdmin && !isImage && !isMarkdown
                                    ? 'Debug Diagnostics'
                                    : 'File viewer'
                            }
                            titleIcon={
                                isAdmin && !isImage ? faBugSlash : faFile
                            }
                            iconColor={'sky'}
                            onClose={() => setShowDebugModal(false)}
                            className={''}
                        >
                            <DebugViewerContainer
                                repoId={repoId}
                                email={email}
                                folderName={folderName}
                                fileName={fileName}
                                mode={mode}
                                isAdmin={isAdmin}
                            />
                        </Modal>
                    )}
                </div>
            );
        }

        return <></>;
    }
};
