import {
    faDatabase,
    faFile,
    faFileCode,
    faFileCsv,
    faFileLines,
    faFilePdf,
    faFileText,
    faFileWord,
    faImage,
} from '@fortawesome/free-solid-svg-icons';
import { getFileFormat } from './getFileFormat';

const fileIconMap = {
    pdf: faFilePdf,
    doc: faFileWord,
    docx: faFileWord,
    txt: faFileText,
    py: faFileCode,
    json: faFileLines,
    csv: faFileCsv,
    db: faDatabase,
    jpg: faImage,
    jpeg: faImage,
    png: faImage,
    bmp: faImage,
    svg: faImage,
    md: faFileLines,
    markdown: faFileLines,
};

export const getFileIcon = (fileName) => {
    const fileFormat = getFileFormat(fileName).toLowerCase();
    return fileIconMap[fileFormat] || faFile;
};
