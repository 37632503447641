import { faTags } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal } from './reusableComponents';
export const CategorizationModal = ({
    setIsCategorizationTemplate,
    isCategorizationTemplate,
    setIsCategorizationTemplateMode,
}) => {
    return (
        <Modal
            title={'Categorization Template'}
            titleIcon={faTags}
            iconColor={'texts-text-tertiary'}
            onClose={() => setIsCategorizationTemplateMode(false)}
        >
            <div className='p-6'>
                <div className='flex flex-row mb-3 mt-1 items-center'>
                    <div className='text-texts-secondary300 mr-4 font-semibold'>
                        Create a categorization template?
                    </div>
                    <div className='flex flex-row items-center'>
                        <label className='flex items-center mr-3'>
                            <input
                                type='radio'
                                name='categorizationTemplate'
                                value='yes'
                                className='mr-2 mt-1'
                                checked={isCategorizationTemplate === true}
                                onChange={() =>
                                    setIsCategorizationTemplate(true)
                                }
                            />
                            Yes
                        </label>

                        <label className='flex items-center'>
                            <input
                                type='radio'
                                name='categorizationTemplate'
                                value='no'
                                className='mr-2 mt-1'
                                checked={isCategorizationTemplate === false}
                                onChange={() =>
                                    setIsCategorizationTemplate(false)
                                }
                            />
                            No
                        </label>
                    </div>
                </div>

                <div className='w-full flex justify-between mt-8'>
                    <Button
                        className='w-1/3 min-w-[120px]'
                        type={'danger'}
                        onClick={() => {
                            setIsCategorizationTemplate(false);
                            setIsCategorizationTemplateMode(false);
                        }}
                        text={'Cancel'}
                    />
                    <Button
                        className='w-1/3 min-w-[120px]'
                        type={'success'}
                        onClick={() => setIsCategorizationTemplateMode(false)}
                        text={'Confirm'}
                    />
                </div>
            </div>
        </Modal>
    );
};
