import React from 'react';
import { Modal } from 'antd';
import { Currency } from '../constants/currency';

const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
};

const InvoiceModal = ({ onClose, invoice, currency }) => {
    if (!invoice) return null;

    return (
        <Modal
            title={
                <span className='text-xl font-semibold'>Invoice Details</span>
            }
            open={true}
            onCancel={onClose}
            footer={null}
            width={600}
            className='invoice-modal'
        >
            <div className='p-8'>
                <div className='text-center mb-8'>
                    <span className='text-gray-500 text-sm uppercase tracking-wider'>
                        Amount Due
                    </span>
                    <p className='text-4xl font-bold text-blue-600 mt-2'>
                        {Currency[currency].symbol}
                        {invoice?.amount_due?.toFixed(2)}
                    </p>
                </div>

                <div className='h-px bg-gray-200'></div>

                <div className='grid grid-cols-3 gap-6 mt-6 text-sm'>
                    <div className='space-y-1'>
                        <span className='text-gray-500'>Total Queries</span>
                        <p className='font-medium'>
                            {invoice?.total_queries?.toLocaleString()}
                        </p>
                    </div>

                    <div className='col-start-3 space-y-1'>
                        <span className='text-gray-500'>Billing Period</span>
                        <p className='font-medium'>
                            {formatDate(invoice?.billing_period_start)} -{' '}
                            {formatDate(invoice?.billing_period_end)}
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default InvoiceModal;
