import React from 'react';
import { camelToTitleCase } from '../utils';

export const ThemeViewer = ({ supportedThemes, theme }) => {
    return (
        <div className='flex justify-start items-center'>
            <div
                className='h-4 w-10 rounded-full shadow-sm border'
                style={{ backgroundColor: supportedThemes[theme] }}
            ></div>
            <span className='ml-3 font-semibold'>
                {camelToTitleCase(theme)}
            </span>
        </div>
    );
};
