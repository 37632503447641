import React, { useEffect, useState } from 'react';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { LoadingView } from '../reusableComponents';
import { Modal } from '../reusableComponents';
import { AnswerContentView } from './answerView/answerContentView';
import { NoChatView } from './noChatView';
import { AnswerActionView } from './answerView/answerActionView';
import { QuestionActionView } from './questionView/questionActionView';
import { QuestionContentView } from './questionView/questionContentView';
import CrawlingStatus from '../CrawlingStatus';
import { getUrl } from '../../utils/getUrl';
const AnswerContainer = ({
    index,
    repoName,
    item,
    user,
    activeQuestion,
    sortedQnAData,
    handleChartFormat,
    handleCustomFormat,
    handleListFormat,
    handleTableFormat,
    setViewDatabaseQuery,
    setActiveDbQuery,
    setFlagIndex,
    setFlagMode,
    isLastAnswer,
    enableScrollToBottom,
    setEnableScrollToBottom,
    isDatabase,
    isSmallScreen,
    chatEndRef,
    isGenerating,
}) => {
    const [activeChart, setActiveChart] = useState(item.answer.chartType);
    const [showDebugModal, setShowDebugModal] = useState(false);
    return (
        <div className='flex w-full items-center justify-end'>
            <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-secondaryChat max-w-[600px] rounded-xl shadow-lg'>
                <AnswerActionView
                    index={index}
                    repoName={repoName}
                    chatObject={item}
                    user={user}
                    size={sortedQnAData.length}
                    handleChartFormat={handleChartFormat}
                    handleCustomFormat={handleCustomFormat}
                    handleListFormat={handleListFormat}
                    handleTableFormat={handleTableFormat}
                    setViewDatabaseQuery={setViewDatabaseQuery}
                    setActiveDbQuery={setActiveDbQuery}
                    flagged={item.flagged}
                    setFlagIndex={setFlagIndex}
                    setFlagMode={setFlagMode}
                    isLastAnswer={isLastAnswer}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                    isDatabase={isDatabase}
                    activeChart={activeChart}
                    setShowDebugModal={setShowDebugModal}
                    activeQuestion={activeQuestion}
                />
                <AnswerContentView
                    chatObject={item}
                    index={index}
                    activeQuestion={activeQuestion}
                    isSmallScreen={isSmallScreen}
                    isDatabase={isDatabase}
                    activeChart={activeChart}
                    setActiveChart={setActiveChart}
                    setEnableScrollToBottom={setEnableScrollToBottom}
                    showDebugModal={showDebugModal}
                    setShowDebugModal={setShowDebugModal}
                    repoName={repoName}
                />

                {/* Dummy reference div, for auto scroll to this div (bottom) when display updates */}
                {enableScrollToBottom && index === sortedQnAData.length - 1 && (
                    <div
                        ref={chatEndRef}
                        className={!isGenerating ? 'mt-2' : ''}
                    />
                )}
            </div>
        </div>
    );
};

export const ChatDisplay = ({
    activeQuestion,
    questionsData,
    flagMode,
    repoName,
    setQuery,
    user,
    setFlagMode,
    setFlagIndex,
    handleChartFormat,
    handleTableFormat,
    handleListFormat,
    handleCustomFormat,
    isGenerating,
    fileToChat,
    isDatabase,
    repository,
}) => {
    const chatEndRef = React.createRef();

    const scrollToBottom = (chatEndRef) => {
        chatEndRef.current?.scrollIntoView({
            behavior: isGenerating ? 'smooth' : 'instant',
        });
    };

    const [viewDatabaseQuery, setViewDatabaseQuery] = useState(false);
    const [activeDbQuery, setActiveDbQuery] = useState('');
    const [enableScrollToBottom, setEnableScrollToBottom] = useState(true);
    const [isCrawlingComplete, setIsCrawlingComplete] = useState(false);
    const [batchId, setBatchId] = useState(null);
    //responsiveness related
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= 1024
    );

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);

    useEffect(() => {
        scrollToBottom(chatEndRef);
    }, [chatEndRef]);

    useEffect(() => {
        setEnableScrollToBottom(true);
    }, [isGenerating, repoName, fileToChat]);

    // Reset crawling status when repository changes
    useEffect(() => {
        const fetchBatchId = async () => {
            try {
                const response = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${
                            process.env.REACT_APP_FAST_API_PORT
                        }/crawl/get_batch_id?repo_id=${encodeURIComponent(
                            repository.repoid
                        )}`
                    ),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodeURIComponent(user.token),
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch batch ID');
                }

                const data = await response.json();
                setBatchId(data.batch_id); // Return the fetched batch ID
            } catch (error) {
                console.error('Error fetching batch ID:', error);
            }
        };

        if (repository?.storage_type === 'internet') {
            setIsCrawlingComplete(false);
            fetchBatchId();
        }
    }, [repository, repoName]);

    let chatList = [];
    if (questionsData) {
        const sortedQnAData = [...questionsData.values()].sort((a, b) => {
            return new Date(a.timestamp) - new Date(b.timestamp);
        });

        chatList = sortedQnAData.map((item, index) => {
            const isLastAnswer = index === sortedQnAData.length - 1;
            return (
                <div key={index}>
                    {/* Question Container */}
                    <div className='flex w-full items-center justify-start'>
                        <div className='w-4/5 whitespace-normal break-words m-3 px-5 py-4 bg-backgrounds-primary100 max-w-[600px] rounded-xl shadow-lg'>
                            <QuestionActionView
                                user={user}
                                chatObject={item}
                                setQuery={setQuery}
                            />
                            <QuestionContentView chatObject={item} />
                        </div>
                    </div>

                    {/* Answer Container */}
                    <AnswerContainer
                        user={user}
                        index={index}
                        repoName={repoName}
                        item={item}
                        activeQuestion={activeQuestion}
                        sortedQnAData={sortedQnAData}
                        handleChartFormat={handleChartFormat}
                        handleCustomFormat={handleCustomFormat}
                        handleListFormat={handleListFormat}
                        handleTableFormat={handleTableFormat}
                        setViewDatabaseQuery={setViewDatabaseQuery}
                        setActiveDbQuery={setActiveDbQuery}
                        setFlagIndex={setFlagIndex}
                        setFlagMode={setFlagMode}
                        isLastAnswer={isLastAnswer}
                        enableScrollToBottom={enableScrollToBottom}
                        setEnableScrollToBottom={setEnableScrollToBottom}
                        isDatabase={isDatabase}
                        isSmallScreen={isSmallScreen}
                        chatEndRef={chatEndRef}
                        isGenerating={isGenerating}
                    />
                </div>
            );
        });
    }

    // First check if we need to show crawling status for internet repositories
    // if (repository?.storage_type === 'internet') {
    //     // CrawlingStatus will return null when complete, allowing the next return to execute
    //     const crawlingStatus = (
    //         <CrawlingStatus repoName={repoName} user={user} />
    //     );

    //     console.log('crawling status', crawlingStatus);

    //     if (crawlingStatus) {
    //         return (
    //             <div className='w-full h-full p-4 flex flex-col'>
    //                 <div className='w-full max-w-3xl mx-auto'>
    //                     {crawlingStatus}
    //                 </div>
    //             </div>
    //         );
    //     }
    // }

    // This will show when either:
    // 1. Not an internet repository
    // 2. Crawling is complete (CrawlingStatus returned null)
    return chatList && chatList.length ? (
        <div className='w-full h-full p-4 flex flex-col justify-between'>
            {/* Database modal */}
            {repository &&
            repository.storage_type === 'internet' &&
            batchId &&
            !isCrawlingComplete ? (
                <CrawlingStatus
                    repoName={repoName}
                    repoId={repository.repoid}
                    user={user}
                    batchId={batchId}
                    onComplete={() => setIsCrawlingComplete(true)}
                />
            ) : (
                <>
                    {viewDatabaseQuery && (
                        <Modal
                            title={'Database Query'}
                            titleIcon={faDatabase}
                            iconColor={'icons-primary'}
                            onClose={() => setViewDatabaseQuery(false)}
                        >
                            <div className='w-96 text-texts-secondary300 p-3'>
                                {activeDbQuery}
                            </div>
                        </Modal>
                    )}

                    <div className='w-full h-full'>{chatList}</div>
                    {isGenerating && (
                        <div className='drop-shadow-sm mt-2'>
                            <LoadingView
                                inLine={true}
                                loadingText={'Generating Answer'}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    ) : (
        <div className='w-full h-full p-4 flex flex-col justify-between'>
            {repository &&
            repository.storage_type === 'internet' &&
            batchId &&
            !isCrawlingComplete ? (
                <CrawlingStatus
                    repoName={repoName}
                    repoId={repository.repoid}
                    user={user}
                    batchId={batchId}
                    onComplete={() => setIsCrawlingComplete(true)}
                />
            ) : (
                <NoChatView
                    isGenerating={isGenerating}
                    repoName={repoName}
                    fileToChat={fileToChat}
                />
            )}
        </div>
    );
};
