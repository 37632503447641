import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
    faExclamationTriangle,
    faFolderPlus,
    faPlus,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from './reusableComponents';
import { getUrl } from './pdfDisplay/utils';
import { ProcessType } from '../constants/processTypes';
import { TaskProgressType } from '../constants/taskProgressTypes';

const AddInternetRepo = ({
    reponame,
    setAddNewMode,
    getAllReposData,
    socket,
    visionRepo,
    onChangeVision,
    visionModel,
    setVisionModel,
    visionModelDropdown,
    error,
    user,
    existingRepo = false,
    onUrlsAdded,
}) => {
    const [urls, setUrls] = useState(['']);
    const [localError, setLocalError] = useState('');

    const handleUrlChange = (index, value) => {
        const newUrls = [...urls];
        newUrls[index] = value;
        setUrls(newUrls);
    };

    const validateUrl = (url) => {
        const urlRegex =
            /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return url === '' || urlRegex.test(url);
    };

    const validateUrls = () => {
        return urls.every((url) => validateUrl(url));
    };

    const addUrlField = (e) => {
        e.preventDefault();
        if (!urls.some((url) => url === '') && validateUrls()) {
            setUrls([...urls, '']);
            setLocalError('');
        } else {
            setLocalError(
                'Please fill in and validate current URLs before adding more'
            );
        }
    };

    const removeUrlField = (index) => {
        const newUrls = urls.filter((_, i) => i !== index);
        setUrls(newUrls);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateUrls()) {
            setLocalError('Please enter valid URLs');
            return;
        }

        const filteredUrls = urls.filter((url) => url !== '');
        if (filteredUrls.length === 0) {
            setLocalError('Please enter at least one URL');
            return;
        }

        try {
            if (!existingRepo) {
                // Original repo creation logic for new repos
                const folderData = {
                    id: user.email,
                    name: reponame,
                    visionRepo: visionRepo,
                    visionModel:
                        visionModel === 'Percision Model'
                            ? 'gemini-1.5-pro'
                            : 'gemini-1.5-flash',
                    vector_store_type: 'CHROMA',
                    summary_flag: false,
                    storage_type: 'internet',
                    repotype: 'vision',
                };

                // Create the repository
                const repoDataString = encodeURIComponent(
                    JSON.stringify(folderData)
                );
                const encodedToken = encodeURIComponent(user.token);

                const repoResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/new_empty_repo/${repoDataString}`
                    ),
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodedToken,
                        },
                    }
                );

                if (!repoResponse.ok) {
                    const errorData = await repoResponse.json();
                    console.error('Repository creation failed:', errorData);
                    throw new Error(
                        `Failed to create repository: ${
                            errorData.error || 'Unknown error'
                        }`
                    );
                }

                // Get the repo ID immediately
                const updatedReposResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/getAllChatsRepos/${user.email}`
                    ),
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodeURIComponent(user.token),
                        },
                    }
                );

                if (!updatedReposResponse.ok) {
                    throw new Error(
                        'Failed to get repository ID after creation'
                    );
                }

                const updatedReposData = await updatedReposResponse.json();
                const newRepo = updatedReposData.Normal.find(
                    (r) => r.name === reponame
                );

                if (!newRepo || !newRepo.repoid) {
                    throw new Error(
                        'Failed to get repository ID after creation'
                    );
                }

                const repoId = newRepo.repoid;

                // Close modal immediately after successful creation
                setAddNewMode(false);

                // Single notification for repository creation
                const completionStatus = {
                    message: 'Repository created successfully',
                    percentageCompletion: 100,
                    process: ProcessType.REPO_CREATION,
                    progress: TaskProgressType.COMPLETED,
                    repoId: repoId,
                    repoName: reponame,
                    statusCode: 200,
                    timeStamp: new Date(),
                    userMail: user.email,
                };
                socket.emit('statusUpdate', completionStatus);

                // Start crawling in background
                try {
                    const crawlResponse = await fetch(
                        getUrl(
                            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/crawl/crawl_and_store`
                        ),
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: encodeURIComponent(user.token),
                            },
                            body: JSON.stringify({
                                repoName: reponame,
                                user: user.email,
                                urls: filteredUrls,
                            }),
                        }
                    );

                    if (!crawlResponse.ok) {
                        throw new Error('Failed to start crawling process');
                    }

                    const crawlData = await crawlResponse.json();
                    const batchId = crawlData.batch_id;

                    // Add notification for crawling start
                    const status = {
                        message: `Started crawling URLs for repository ${reponame}`,
                        percentageCompletion: 0,
                        process: ProcessType.CRAWLING,
                        progress: TaskProgressType.STARTED,
                        repoId: null,
                        repoName: reponame,
                        statusCode: 200,
                        timeStamp: new Date(),
                        userMail: user.email,
                        batchId: batchId,
                    };
                    socket.emit('statusUpdate', status);
                } catch (error) {
                    console.error('Error starting crawl process:', error);
                    // Don't throw here since repo is already created
                }

                // Refresh repos list
                await getAllReposData();
                onUrlsAdded?.();
            } else {
                // Close modal immediately after successful creation/update
                setAddNewMode(false);

                // Start crawling in background
                try {
                    const crawlResponse = await fetch(
                        getUrl(
                            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/crawl/crawl_and_store`
                        ),
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: encodeURIComponent(user.token),
                            },
                            body: JSON.stringify({
                                repoName: reponame,
                                user: user.email,
                                urls: filteredUrls,
                            }),
                        }
                    );

                    if (!crawlResponse.ok) {
                        throw new Error('Failed to start crawling process');
                    }

                    const crawlData = await crawlResponse.json();
                    const batchId = crawlData.batch_id;

                    // Add notification for crawling start
                    const status = {
                        message: `Started crawling URLs for repository ${reponame}`,
                        percentageCompletion: 0,
                        process: ProcessType.CRAWLING,
                        progress: TaskProgressType.STARTED,
                        repoId: null,
                        repoName: reponame,
                        statusCode: 200,
                        timeStamp: new Date(),
                        userMail: user.email,
                        batchId: batchId,
                    };
                    socket.emit('statusUpdate', status);
                } catch (error) {
                    console.error('Error starting crawl process:', error);
                }

                // Refresh repos list
                await getAllReposData();
            }
        } catch (error) {
            console.error('Error:', error);
            setLocalError('Failed: ' + error.message);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={existingRepo ? 'w-[800px] p-4' : ''}
        >
            <div className='flex flex-col space-y-4 bg-backgrounds-white rounded-xl my-4 p-4 border-2 border-dashed border-backgrounds-primary300'>
                <div className='text-center text-texts-secondary300 mb-4'>
                    {existingRepo
                        ? 'Add URLs to Repository'
                        : 'Add URLs to create an Internet Repository'}
                </div>

                {urls.map((url, index) => (
                    <div
                        key={index}
                        className={`flex items-center ${
                            existingRepo ? 'space-x-3' : 'space-x-2'
                        }`}
                    >
                        <input
                            type='text'
                            value={url}
                            onChange={(e) =>
                                handleUrlChange(index, e.target.value)
                            }
                            placeholder='Enter URL (e.g., https://example.com)'
                            className={`flex-1 p-2 border rounded-xl focus:outline-none focus:ring-2 focus:ring-backgrounds-primary300 ${
                                existingRepo ? 'min-w-[600px]' : ''
                            }`}
                        />
                        {urls.length > 1 && (
                            <Button
                                type='danger'
                                icon={faTrash}
                                onClick={() => removeUrlField(index)}
                                className='p-2'
                            />
                        )}
                    </div>
                ))}

                <Button
                    type='primary'
                    icon={faPlus}
                    text='Add URL'
                    onClick={addUrlField}
                    className='w-full'
                    disabled={urls.some((url) => url === '') || !validateUrls()}
                />
            </div>

            {!existingRepo && (
                <div className='flex items-center mt-4'>
                    <div className='w-1/8 font-semibold text-texts-secondary300'>
                        <label htmlFor='Vision Model'>Vision Model</label>
                    </div>
                    <div className='ml-2 w-full md:w-1/4'>
                        <div className='relative'>
                            <select
                                id='Vision Model'
                                className='block w-auto md:w-30 p-2 mt-1 rounded-xl shadow-sm focus:ring-backgrounds-primary500 focus:border-backgrounds-primary400 hover:outline-none focus:outline-backgrounds-primary200 border hover:border-backgrounds-primary300'
                                value={visionModel}
                                onChange={(e) => setVisionModel(e.target.value)}
                            >
                                <option disabled={true} value={null}>
                                    Select a Vision Model
                                </option>
                                {visionModelDropdown.map((method) => (
                                    <option key={method} value={method}>
                                        {method}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            )}

            {(error || localError) && (
                <div className='flex justify-center items-center text-buttons-alert400 mt-3'>
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className='mr-2'
                    />
                    {error || localError}
                </div>
            )}

            <div className='flex items-center justify-center mt-4'>
                <div className='flex items-center justify-between w-full'>
                    <Button
                        type='danger'
                        icon={faCircleXmark}
                        text={'Cancel'}
                        tile={'Cancel and close the modal'}
                        className='w-1/3'
                        onClick={() => setAddNewMode(false)}
                    />

                    <button
                        className={`flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 ${
                            !urls.some((url) => url !== '') ||
                            error ||
                            localError
                                ? 'text-texts-secondary300 cursor-not-allowed'
                                : 'hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                        }`}
                        type='submit'
                        title={
                            existingRepo
                                ? 'Add URLs to repository'
                                : 'Create internet repository'
                        }
                        disabled={
                            !urls.some((url) => url !== '') ||
                            error ||
                            localError
                        }
                    >
                        <FontAwesomeIcon icon={faFolderPlus} className='mr-2' />
                        {existingRepo ? 'Add URLs' : 'Add'}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default AddInternetRepo;
