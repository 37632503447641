import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkHtml from 'remark-html';
import remarkEmoji from 'remark-emoji';
import {
    LoadingComponent,
    ErrorDataComponent,
} from '../pdfDisplay/reusableComponents';

export const MarkdownViewer = ({ fileName, fileUrl }) => {
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMarkdownContent = async () => {
            try {
                const response = await fetch(fileUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch markdown content');
                }
                const text = await response.text();
                // Replace special characters with HTML entities
                const processedText = text
                    .replace(/\(c\)/gi, '&copy;')
                    .replace(/\(r\)/gi, '&reg;')
                    .replace(/\(tm\)/gi, '&trade;')
                    .replace(/\(p\)/gi, '&para;')
                    .replace(/\+-/g, '&plusmn;');

                setContent(processedText);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        if (fileUrl) {
            fetchMarkdownContent();
        }
    }, [fileUrl]);

    if (isLoading) {
        return <LoadingComponent fileName={fileName} />;
    }

    if (error) {
        return <ErrorDataComponent fileName={fileName} />;
    }

    return (
        <div className='markdown-content relative h-full'>
            <div className='absolute inset-0 overflow-auto p-4'>
                <ReactMarkdown
                    remarkPlugins={[remarkGfm, remarkHtml, [remarkEmoji]]}
                    components={{
                        // Table specific components
                        table: ({ node, ...props }) => (
                            <div className='my-4 overflow-x-auto'>
                                <table
                                    className='min-w-full divide-y divide-gray-300 border border-gray-300'
                                    {...props}
                                />
                            </div>
                        ),
                        thead: ({ node, ...props }) => (
                            <thead className='bg-gray-50' {...props} />
                        ),
                        tbody: ({ node, ...props }) => (
                            <tbody
                                className='divide-y divide-gray-200 bg-white'
                                {...props}
                            />
                        ),
                        tr: ({ node, ...props }) => (
                            <tr className='hover:bg-gray-50' {...props} />
                        ),
                        th: ({ node, ...props }) => (
                            <th
                                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                                {...props}
                            />
                        ),
                        td: ({ node, ...props }) => (
                            <td
                                className='px-6 py-4 text-sm text-gray-500 whitespace-nowrap'
                                {...props}
                            />
                        ),
                        // Other markdown elements
                        h1: ({ node, ...props }) => (
                            <h1
                                className='text-2xl font-bold my-4'
                                {...props}
                            />
                        ),
                        h2: ({ node, ...props }) => (
                            <h2 className='text-xl font-bold my-3' {...props} />
                        ),
                        h3: ({ node, ...props }) => (
                            <h3 className='text-lg font-bold my-2' {...props} />
                        ),
                        p: ({ node, ...props }) => (
                            <p className='my-2' {...props} />
                        ),
                        ul: ({ node, ...props }) => (
                            <ul className='list-disc ml-4 my-2' {...props} />
                        ),
                        ol: ({ node, ...props }) => (
                            <ol className='list-decimal ml-4 my-2' {...props} />
                        ),
                        code: ({ node, inline, ...props }) => (
                            <code
                                className='bg-gray-100 rounded px-1 py-0.5'
                                {...props}
                            />
                        ),
                        pre: ({ node, ...props }) => (
                            <pre
                                className='bg-gray-100 rounded p-4 overflow-x-auto my-2'
                                {...props}
                            />
                        ),
                        blockquote: ({ node, ...props }) => (
                            <blockquote
                                className='border-l-4 border-gray-300 pl-4 my-2 italic'
                                {...props}
                            />
                        ),
                        a: ({ node, ...props }) => (
                            <a
                                className='text-blue-600 hover:text-blue-800 hover:underline'
                                target='_blank'
                                rel='noopener noreferrer'
                                {...props}
                            />
                        ),
                    }}
                >
                    {content}
                </ReactMarkdown>
            </div>
        </div>
    );
};
