import React from 'react';
import { getFileFormat, isImageFile, isMarkdownFile } from '../utils';
import { PdfDebugContainer } from './pdfDisplay/debug/pdfDebugContainer';
import { SingleImageContainer } from './imageViewer/singleImageContainer';
import { SingleMarkdownContainer } from './markdownViewer/singleMarkdownContainer';
export const DebugViewerContainer = ({
    repoId,
    email,
    folderName,
    fileName,
    mode,
    isAdmin,
}) => {
    const extension = getFileFormat(fileName);

    if (isImageFile(extension)) {
        return (
            <SingleImageContainer
                repoId={repoId}
                email={email}
                folderName={folderName}
                fileName={fileName}
            />
        );
    } else if (isMarkdownFile(extension)) {
        return (
            <SingleMarkdownContainer
                repoId={repoId}
                email={email}
                folderName={folderName}
                fileName={fileName}
            />
        );
    }

    return (
        <PdfDebugContainer
            repoId={repoId}
            email={email}
            folderName={folderName}
            fileName={fileName}
            mode={mode}
            isAdmin={isAdmin}
        />
    );
};
