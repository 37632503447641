import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Pagination, message } from 'antd';
import { Pie, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import 'antd/dist/reset.css';
import { faDownload, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, LoadingView } from './reusableComponents';
import { getUrl } from './pdfDisplay/utils';
import { SUBSCRIPTION_PLANS } from '../utils/planUtils';
import InvoiceModal from './InvoiceModal';
const { Option } = Select;

// Add this helper function near the top of the file
const isCurrentSubscription = (subscription) => {
    if (!subscription?.end_date) return false;
    const endDate = new Date(subscription.end_date);
    const now = new Date();
    return endDate >= now;
};

// Formats a date string into a localized short format (e.g., "Jan 15, 2024")
const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
};

// Formats a date range into a standard format (e.g., "Nov 7, 2025 - Dec 7, 2025")
const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return '';
    const start = new Date(startDate);
    const end = new Date(endDate);

    return `${start.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })} - ${end.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    })}`;
};

const chartOptions = {
    plugins: {
        legend: {
            display: false, // This disables the legend
        },
    },
};

// Separate chart options
const dailyBarOptions = {
    ...chartOptions,
    scales: {
        x: {
            title: {
                display: true,
                text: 'Date',
                color: '#666',
                font: { size: 12 },
            },
        },
        y: {
            title: {
                display: true,
                text: 'Number of Queries',
                color: '#666',
                font: { size: 12 },
            },
            beginAtZero: true,
        },
    },
};

const userBarOptions = {
    ...chartOptions,
    scales: {
        x: {
            title: {
                display: true,
                text: 'Users',
                color: '#666',
                font: { size: 12 },
            },
        },
        y: {
            title: {
                display: true,
                text: 'Queries Used',
                color: '#666',
                font: { size: 12 },
            },
            beginAtZero: true,
        },
    },
};

// Add new view type constant
const VIEW_TYPES = {
    DAILY: 'daily',
    MONTHLY: 'monthly',
    USERS: 'users',
};

const SubscriptionDashboard = ({ setManageSubscriptionModal }) => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [daily_data, setDailyData] = useState([]);
    const [invoiceData, setInvoiceData] = useState({});
    const user = JSON.parse(localStorage.getItem('current'));
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
    const [loadingInvoice, setLoadingInvoice] = useState(false);
    const itemsPerPage = 30;
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);
    const [viewType, setViewType] = useState(VIEW_TYPES.DAILY);
    const [usersConsumption, setUsersConsumption] = useState([]);
    const [userCurrentPage, setUserCurrentPage] = useState(1);
    const usersPerPage = 10; // Show 10 users per page
    const [monthlyData, setMonthlyData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [filteredDailyData, setFilteredDailyData] = useState([]);
    const [filteredUsersData, setFilteredUsersData] = useState([]);
    const [isOrgMember, setIsOrgMember] = useState(false);
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);
    const [orgId, setOrgId] = useState(null);
    const [showInvoice, setShowInvoice] = useState(false);

    // Add new effect to fetch organization details
    useEffect(() => {
        const fetchOrgDetails = async () => {
            try {
                const response = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/details`
                    ),
                    {
                        headers: {
                            Authorization: encodeURIComponent(user.token),
                        },
                    }
                );

                if (response.data?.details) {
                    setIsOrgMember(true);
                    setOrgId(response.data.details.id);
                    setIsOrgAdmin(response.data.details.userRole === 'admin');
                } else {
                    setIsOrgMember(false);
                    setIsOrgAdmin(false);
                }
            } catch (error) {
                console.error('Error fetching organization details:', error);
                setIsOrgMember(false);
                setIsOrgAdmin(false);
            }
        };

        if (user?.token) {
            fetchOrgDetails();
        }
    }, [user?.token]);

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            setLoadingSubscriptions(true);
            try {
                const user = JSON.parse(localStorage.getItem('current'));
                if (!user?.email) {
                    throw new Error('User email not found');
                }

                // Fetch subscription history first
                const historyResponse = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/subscription-history/${user.email}`
                    )
                );

                if (!historyResponse.ok) {
                    throw new Error('Failed to fetch subscription history');
                }

                const historyData = await historyResponse.json();
                setSubscriptionHistory(historyData.subscription_history);

                const showPersonalData = isOrgMember && !isOrgAdmin;
                const params = new URLSearchParams();
                params.append('show_personal_data_only', showPersonalData);
                // Fetch current plan
                const response = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/current-plan/${user.email}?${params}`
                    )
                );

                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch current plan: ${response.status}`
                    );
                }

                const data = await response.json();
                const transformedData = {
                    ...data,
                    plan_name: data.name || SUBSCRIPTION_PLANS.FREE,
                };

                setSubscriptions([transformedData]);
                setSelectedSubscription(transformedData);
                setDailyData(transformedData.daily_data || []);
            } catch (error) {
                console.error(
                    'Error fetching subscription data:',
                    error.message
                );
                message.error('Failed to load subscription data');
                // Set default values
                const defaultData = {
                    plan_name: SUBSCRIPTION_PLANS.FREE,
                    queries_processed: 0,
                    total_queries_allowed: 0,
                    daily_data: [],
                };
                setSubscriptionHistory([]);
                setSubscriptions([defaultData]);
                setSelectedSubscription(defaultData);
                setDailyData([]);
            } finally {
                setLoadingSubscriptions(false);
            }
        };

        fetchSubscriptionData();
    }, [isOrgMember, isOrgAdmin]);

    useEffect(() => {
        const fetchInvoiceData = async () => {
            if (
                !selectedSubscription?.id ||
                selectedSubscription.plan_name === SUBSCRIPTION_PLANS.FREE ||
                selectedSubscription.plan_name ===
                    SUBSCRIPTION_PLANS.ENTERPRISE ||
                (isOrgMember && !isOrgAdmin)
            )
                return;

            setLoadingInvoice(true);
            try {
                const user = JSON.parse(localStorage.getItem('current'));
                if (!user?.email) {
                    throw new Error('User email not found');
                }

                const formData = new FormData();
                formData.append('email', user.email);
                formData.append(
                    'plan_id',
                    selectedSubscription.razorpay_plan_id
                );
                formData.append('start_date', selectedSubscription.start_date);

                const response = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/invoice`
                    ),
                    {
                        method: 'POST',
                        body: formData,
                    }
                );

                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch invoice: ${response.status}`
                    );
                }

                const data = await response.json();
                setInvoiceData(data || {});
            } catch (error) {
                console.error('Error fetching invoice data:', error.message);
                message.error('Failed to load invoice data');
                setInvoiceData({});
            } finally {
                setLoadingInvoice(false);
            }
        };

        fetchInvoiceData();
    }, [selectedSubscription, isOrgMember, isOrgAdmin]);

    // Modify the effect to only fetch for org members
    useEffect(() => {
        const fetchUsersConsumption = async () => {
            if (!selectedSubscription || !isOrgMember) return;

            // For non-admin org users, always fetch personal data
            if (!isOrgAdmin && viewType === VIEW_TYPES.USERS) return;

            try {
                if (!user?.email) {
                    throw new Error('User email not found');
                }

                // Build URL with query parameters
                const params = new URLSearchParams({
                    subscription_id:
                        selectedSubscription.razorpay_subscription_id,
                });

                // Add date filters if a month is selected
                if (selectedMonth) {
                    const [year, month] = selectedMonth.split('-');
                    const startDate = new Date(year, month - 1, 1);
                    const endDate = new Date(year, month, 0);
                    params.append('start_date', startDate.toISOString());
                    params.append('end_date', endDate.toISOString());
                }

                // Always add personal filter for non-admin users
                if (!isOrgAdmin) {
                    params.append('personal', 'true');
                    params.append('user_email', user.email);
                }

                const url = getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/org-users-consumption/${user.email}?${params}`
                );

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch users consumption data');
                }

                const data = await response.json();
                setUsersConsumption(data.users_consumption);
            } catch (error) {
                message.error('Failed to load users consumption data');
                setUsersConsumption([]);
            }
        };

        fetchUsersConsumption();
    }, [
        viewType,
        selectedSubscription,
        selectedMonth,
        user?.email,
        isOrgAdmin,
        isOrgMember,
    ]);

    const handleDownloadInvoice = async (id) => {
        try {
            if (!id) {
                message.warning('No invoice available for download');
                return;
            }

            const user = JSON.parse(localStorage.getItem('current'));
            if (!user?.token) {
                throw new Error('Authentication token not found');
            }

            // Check if user has admin rights if they belong to an organization
            if (user.organization_id) {
                const adminCheck = await axios.get(
                    getUrl(
                        `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/organization/details`
                    ),
                    {
                        headers: {
                            Authorization: encodeURIComponent(user.token),
                        },
                    }
                );

                if (adminCheck.data?.details?.userRole !== 'admin') {
                    message.error(
                        'Only organization administrators can download invoices'
                    );
                    return;
                }
            }

            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${
                    process.env.REACT_APP_NODE_SERVER_PORT
                }/download/${id.replace(/\.pdf$/, '')}`
            );

            const response = await axios.get(dynamicUrl, {
                headers: {
                    Authorization: encodeURIComponent(user.token),
                },
                responseType: 'blob',
            });

            if (response.status === 200) {
                const blob = response.data;
                const blobUrl = window.URL.createObjectURL(blob);
                const tempLink = document.createElement('a');
                tempLink.href = blobUrl;
                tempLink.setAttribute('download', `${id}`);
                tempLink.setAttribute('target', '_blank');
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobUrl);
                message.success('Invoice downloaded successfully');
            } else {
                throw new Error(`Failed to download file: ${response.status}`);
            }
        } catch (err) {
            message.error('Failed to download invoice');
        }
    };

    const handleDateChange = async (value) => {
        try {
            const [startDate, endDate] = value.split('_');
            const user = JSON.parse(localStorage.getItem('current'));

            if (!user?.email) {
                throw new Error('User email not found');
            }

            const params = new URLSearchParams({
                start_date: startDate,
                end_date: endDate,
            });

            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/current-plan/${user.email}?${params}`
                )
            );

            if (!response.ok) {
                throw new Error(
                    `Failed to fetch plan data: ${response.status}`
                );
            }

            const data = await response.json();
            const transformedData = {
                ...data,
                plan_name: data.name || SUBSCRIPTION_PLANS.FREE,
            };

            // Clear invoice data when changing subscription
            setInvoiceData({});
            setSelectedSubscription(transformedData);
            setDailyData(transformedData.daily_data || []);

            // Set current month if it's a yearly subscription
            if (isYearlySubscription(transformedData)) {
                const now = new Date();
                const currentMonth = `${now.getFullYear()}-${String(
                    now.getMonth() + 1
                ).padStart(2, '0')}`;
                setSelectedMonth(currentMonth);
            } else {
                setSelectedMonth(null);
            }
        } catch (error) {
            console.error('Error changing subscription date:', error);
            message.error('Failed to update subscription data');
        }
    };

    // Pie Chart Data
    const pieData = {
        labels: ['Queries Processed', 'Queries Left'],

        datasets: [
            {
                data: [
                    selectedSubscription?.queries_processed,
                    selectedSubscription?.total_queries_allowed -
                        selectedSubscription?.queries_processed,
                ],
                backgroundColor: ['#03A9F4', '#F1F5FF'],
            },
        ],
    };

    // Add helper to get available months from daily data
    const getAvailableMonths = () => {
        if (!daily_data?.length) return [];

        const months = new Set();
        daily_data.forEach((item) => {
            const date = new Date(item.day);
            const monthKey = `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, '0')}`;
            months.add(monthKey);
        });

        return Array.from(months).sort();
    };

    // Add effect to filter data when month is selected
    useEffect(() => {
        if (!selectedMonth || !daily_data) {
            setFilteredDailyData(daily_data);
            setFilteredUsersData(usersConsumption);
            return;
        }

        // Filter daily data
        const filteredDaily = daily_data.filter((item) => {
            const date = new Date(item.day);
            const itemMonth = `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, '0')}`;
            return itemMonth === selectedMonth;
        });
        setFilteredDailyData(filteredDaily);

        // Filter users data
        const filteredUsers = usersConsumption
            .map((user) => ({
                ...user,
                queries_processed: user.monthly_breakdown?.[selectedMonth] || 0,
            }))
            .filter((user) => user.queries_processed > 0);

        setFilteredUsersData(filteredUsers);
    }, [selectedMonth, daily_data, usersConsumption]);

    // Separate data for daily usage
    const dailyBarData = {
        labels: filteredDailyData
            ?.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
            )
            ?.map((item) => {
                const date = new Date(item?.day);
                return date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                });
            }),
        datasets: [
            {
                label: 'Queries Processed',
                data: filteredDailyData
                    ?.slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                    )
                    ?.map((item) => item?.queries_processed),
                backgroundColor: '#03A9F4',
            },
        ],
    };

    // Separate data for user usage
    const userBarData = {
        labels: filteredUsersData
            .slice(
                (userCurrentPage - 1) * usersPerPage,
                userCurrentPage * usersPerPage
            )
            .map((item) => item.email),
        datasets: [
            {
                label: 'User Consumption',
                data: filteredUsersData
                    .slice(
                        (userCurrentPage - 1) * usersPerPage,
                        userCurrentPage * usersPerPage
                    )
                    .map((item) => item.queries_processed),
                backgroundColor: '#03A9F4',
            },
        ],
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleUserPageChange = (page) => {
        setUserCurrentPage(page);
    };

    // Add helper to check if subscription is yearly
    const isYearlySubscription = (subscription) => {
        if (!subscription?.start_date || !subscription?.end_date) return false;
        const start = new Date(subscription.start_date);
        const end = new Date(subscription.end_date);
        const diffInMonths =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());
        return diffInMonths >= 12;
    };

    // Add effect to calculate monthly data when daily_data changes
    useEffect(() => {
        if (!daily_data?.length) return;

        const monthlyUsage = daily_data.reduce((acc, curr) => {
            const date = new Date(curr.day);
            const monthKey = `${date.getFullYear()}-${String(
                date.getMonth() + 1
            ).padStart(2, '0')}`;

            if (!acc[monthKey]) {
                acc[monthKey] = {
                    month: monthKey,
                    queries_processed: 0,
                };
            }
            acc[monthKey].queries_processed += curr.queries_processed;
            return acc;
        }, {});

        setMonthlyData(Object.values(monthlyUsage));
    }, [daily_data]);

    // Add monthly bar data
    const monthlyBarData = {
        labels: monthlyData?.map((item) => {
            const [year, month] = item.month.split('-');
            return new Date(year, month - 1).toLocaleDateString('en-US', {
                month: 'short',
                year: 'numeric',
            });
        }),
        datasets: [
            {
                label: 'Monthly Queries Processed',
                data: monthlyData?.map((item) => item.queries_processed),
                backgroundColor: '#03A9F4',
            },
        ],
    };

    useEffect(() => {
        const checkPendingInvoice = async () => {
            if (
                !orgId ||
                !isOrgAdmin ||
                !selectedSubscription?.razorpay_subscription_id ||
                selectedSubscription?.plan_name !==
                    SUBSCRIPTION_PLANS.ENTERPRISE
            )
                return;
            setLoadingInvoice(true);
            try {
                const startDate = selectedSubscription?.created_at
                    ? selectedSubscription?.created_at
                    : null;

                const formData = new FormData();
                formData.append('org_id', orgId);
                if (startDate) {
                    formData.append('start_date', startDate);
                }
                formData.append(
                    'subscription_id',
                    selectedSubscription?.razorpay_subscription_id
                );

                const response = await axios.post(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/subscription_routes/organization/invoices`
                    ),
                    formData
                );

                if (response.data) {
                    const latestInvoice = response.data;
                    if (latestInvoice.status === 'PENDING') {
                        setInvoiceData(latestInvoice);
                        setShowInvoice(true);
                    }
                }
            } catch (error) {
                message.error('Failed to fetch invoice details');
            } finally {
                setLoadingInvoice(false);
            }
        };

        checkPendingInvoice();
    }, [orgId, isOrgAdmin, selectedSubscription]);

    if (loadingSubscriptions) {
        return <LoadingView loadingText='Loading subscriptions' />;
    }

    if (!selectedSubscription) {
        return <LoadingView loadingText='Loading subscription data' />;
    }

    if (loadingInvoice) {
        return <LoadingView loadingText='Loading invoice data' />;
    }

    return (
        <div className='h-[85vh] px-2 bg-gray-50 flex flex-col overflow-hidden'>
            {/* Header Section - Show full version for non-org users or org admins */}
            <div className='w-full flex items-center justify-between py-4'>
                <div className='flex items-center space-x-4'>
                    {selectedSubscription?.plan_name !==
                        SUBSCRIPTION_PLANS.FREE && (
                        <>
                            <div className='flex items-center space-x-4'>
                                <label className='text-lg font-medium text-backgrounds-primary300'>
                                    {isOrgMember && !isOrgAdmin
                                        ? 'Usage Period:'
                                        : 'Subscription:'}
                                </label>

                                {!isOrgMember || isOrgAdmin ? (
                                    <Select
                                        placeholder='Select Date Range'
                                        onChange={handleDateChange}
                                        defaultValue={
                                            selectedSubscription?.start_date &&
                                            selectedSubscription?.end_date
                                                ? formatDateRange(
                                                      selectedSubscription.start_date,
                                                      selectedSubscription.end_date
                                                  )
                                                : 'Select Date Range'
                                        }
                                        className='w-fit'
                                    >
                                        {subscriptionHistory.map((sub) => (
                                            <Option
                                                key={`${sub.start_date}_${sub.end_date}`}
                                                value={`${sub.start_date}_${sub.end_date}`}
                                            >
                                                {formatDateRange(
                                                    sub.start_date,
                                                    sub.end_date
                                                )}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <span className='text-base text-blue-600 font-medium'>
                                        {formatDateRange(
                                            selectedSubscription.start_date,
                                            selectedSubscription.end_date
                                        )}
                                    </span>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className='flex gap-2'>
                    {(!isOrgMember || isOrgAdmin) && (
                        <Button
                            className='h-8 mr-2 px-2 text-backgrounds-primary300'
                            hoverText='Manage Subscription'
                            text='Manage Subscription'
                            icon={faExchangeAlt}
                            type='success'
                            onClick={() => setManageSubscriptionModal(true)}
                        />
                    )}
                </div>
            </div>

            {/* Main Content Grid */}
            <div className='grid grid-cols-12 gap-4 h-[calc(100%-2rem)] overflow-hidden'>
                {/* Left Column - Show for non-org users or org admins */}
                {(!isOrgMember || isOrgAdmin) && (
                    <div className='col-span-4 bg-white shadow rounded-lg p-5 overflow-hidden flex flex-col'>
                        <div className='flex items-center justify-between mb-4 flex-none'>
                            <span className='text-base text-texts-secondary300'>
                                Current Plan
                            </span>
                            <span className='text-texts-tertiary300 text-xl font-semibold'>
                                {selectedSubscription.plan_name.split('_')[0]}
                            </span>
                        </div>

                        {/* Only show pie chart if not enterprise plan */}
                        {selectedSubscription.plan_name !==
                            SUBSCRIPTION_PLANS.ENTERPRISE && (
                            <div className='w-full h-[180px] flex-none'>
                                <Pie
                                    data={pieData}
                                    options={{
                                        ...chartOptions,
                                        maintainAspectRatio: false,
                                    }}
                                />
                            </div>
                        )}

                        {/* Original stats layout */}
                        <div className='flex-none space-y-4 mt-4'>
                            {selectedSubscription?.plan_name !==
                                SUBSCRIPTION_PLANS.FREE && (
                                <>
                                    <div className='flex justify-between items-center'>
                                        <span className='text-base text-gray-600'>
                                            Status
                                        </span>
                                        <span
                                            className={`text-base font-medium ${
                                                isCurrentSubscription(
                                                    selectedSubscription
                                                )
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                            }`}
                                        >
                                            {isCurrentSubscription(
                                                selectedSubscription
                                            )
                                                ? 'Active'
                                                : 'Expired'}
                                        </span>
                                    </div>
                                    {isCurrentSubscription(
                                        selectedSubscription
                                    ) && (
                                        <>
                                            <div className='flex justify-between items-center'>
                                                <span className='text-base text-gray-600'>
                                                    Started On
                                                </span>
                                                <span className='text-base text-blue-600 font-medium'>
                                                    {formatDate(
                                                        selectedSubscription?.start_date
                                                    )}
                                                </span>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <span className='text-base text-gray-600'>
                                                    Active Till
                                                </span>
                                                <span className='text-base text-blue-600 font-medium'>
                                                    {formatDate(
                                                        selectedSubscription?.end_date
                                                    )}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    {(invoiceData?.invoice_id ||
                                        invoiceData?.invoice_file) && (
                                        <div className='flex justify-between items-center'>
                                            <span className='text-base text-gray-600'>
                                                Invoice
                                            </span>
                                            <Button
                                                className='text-backgrounds-primary300'
                                                icon={faDownload}
                                                onClick={() =>
                                                    handleDownloadInvoice(
                                                        invoiceData?.invoice_id ||
                                                            invoiceData?.invoice_file
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            <div className='flex justify-between items-center'>
                                <span className='text-base text-gray-600'>
                                    Total Queries Processed
                                </span>
                                <span className='text-base text-blue-600 font-medium'>
                                    {selectedSubscription?.queries_processed}
                                </span>
                            </div>
                            <div className='flex justify-between items-center'>
                                <span className='text-base text-gray-600'>
                                    Daily Average Usage (queries/day)
                                </span>
                                <span className='text-base text-blue-600 font-medium'>
                                    {(() => {
                                        const startDate = new Date(
                                            selectedSubscription?.start_date
                                        );
                                        const endDate = isCurrentSubscription(
                                            selectedSubscription
                                        )
                                            ? new Date()
                                            : new Date(
                                                  selectedSubscription?.end_date
                                              );
                                        const daysDiff = Math.max(
                                            1,
                                            Math.floor(
                                                (endDate - startDate) /
                                                    (1000 * 60 * 60 * 24)
                                            ) + 1
                                        );
                                        const average = Math.floor(
                                            selectedSubscription?.queries_processed /
                                                daysDiff
                                        );
                                        return `${average}`;
                                    })()}
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                {/* Right Column - Adjust width based on user type */}
                <div
                    className={`${
                        !isOrgMember || isOrgAdmin
                            ? 'col-span-8'
                            : 'col-span-12'
                    } bg-white shadow rounded-lg p-3 pb-2 flex flex-col overflow-hidden`}
                >
                    <div className='flex justify-between items-center mb-4'>
                        <h3 className='text-lg font-medium text-backgrounds-primary300'>
                            {isOrgMember && !isOrgAdmin
                                ? 'Your Usage Analytics'
                                : 'Usage Analytics'}
                        </h3>
                        {(!isOrgMember || isOrgAdmin) && (
                            <div className='flex items-center gap-4'>
                                {isYearlySubscription(selectedSubscription) && (
                                    <Select
                                        value={selectedMonth}
                                        onChange={setSelectedMonth}
                                        className='w-40'
                                        placeholder='Select Month'
                                    >
                                        {getAvailableMonths().map((month) => {
                                            const [year, monthNum] =
                                                month.split('-');
                                            const date = new Date(
                                                year,
                                                monthNum - 1
                                            );
                                            return (
                                                <Option
                                                    key={month}
                                                    value={month}
                                                >
                                                    {date.toLocaleDateString(
                                                        'en-US',
                                                        {
                                                            month: 'long',
                                                            year: 'numeric',
                                                        }
                                                    )}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                )}
                                {selectedSubscription?.plan_name !==
                                    SUBSCRIPTION_PLANS.FREE &&
                                    selectedSubscription?.organization_id &&
                                    isOrgAdmin && (
                                        <Select
                                            value={viewType}
                                            onChange={setViewType}
                                            className='w-38'
                                        >
                                            <Option value={VIEW_TYPES.DAILY}>
                                                Daily Usage
                                            </Option>
                                            <Option value={VIEW_TYPES.USERS}>
                                                Per User Usage
                                            </Option>
                                        </Select>
                                    )}
                            </div>
                        )}
                    </div>
                    <div className='h-[400px]'>
                        {viewType === VIEW_TYPES.DAILY ? (
                            <>
                                <Bar
                                    data={dailyBarData}
                                    options={{
                                        ...dailyBarOptions,
                                        maintainAspectRatio: false,
                                        responsive: true,
                                    }}
                                />
                                {filteredDailyData?.length > itemsPerPage && (
                                    <div className='mt-4 flex justify-center'>
                                        <Pagination
                                            current={currentPage}
                                            total={
                                                filteredDailyData?.length || 0
                                            }
                                            pageSize={itemsPerPage}
                                            onChange={handlePageChange}
                                            showSizeChanger={false}
                                            simple
                                        />
                                    </div>
                                )}
                            </>
                        ) : viewType === VIEW_TYPES.MONTHLY ? (
                            <Bar
                                data={monthlyBarData}
                                options={{
                                    ...dailyBarOptions,
                                    maintainAspectRatio: false,
                                    responsive: true,
                                }}
                            />
                        ) : (
                            <>
                                <Bar
                                    data={userBarData}
                                    options={{
                                        ...userBarOptions,
                                        maintainAspectRatio: false,
                                        responsive: true,
                                    }}
                                />
                                {filteredUsersData.length > usersPerPage && (
                                    <div className='mt-4 flex justify-center'>
                                        <Pagination
                                            current={userCurrentPage}
                                            total={filteredUsersData.length}
                                            pageSize={usersPerPage}
                                            onChange={handleUserPageChange}
                                            showSizeChanger={false}
                                            simple
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Invoice Modal */}

            {showInvoice && (
                <InvoiceModal
                    onClose={() => setShowInvoice(false)}
                    invoice={invoiceData}
                    currency={user?.currency}
                />
            )}
        </div>
    );
};

export default SubscriptionDashboard;
