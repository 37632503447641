import React, { useState } from 'react';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from './reusableComponents';
import PricingPageOnLogin from './PricingPageOnLogin';
import SubscriptionDashboard from './SubscriptionDashboard';

const TransactionList = () => {
    const [manageSubscriptionModal, setManageSubscriptionModal] =
        useState(false);
    return (
        <div className='z-50'>
            <SubscriptionDashboard
                setManageSubscriptionModal={setManageSubscriptionModal}
            ></SubscriptionDashboard>

            {manageSubscriptionModal && (
                <Modal
                    title={'Manage Subscription'}
                    titleIcon={faExchangeAlt}
                    iconColor={'green'}
                    onClose={() => setManageSubscriptionModal(false)}
                >
                    <PricingPageOnLogin></PricingPageOnLogin>
                </Modal>
            )}
        </div>
    );
};

export default TransactionList;
