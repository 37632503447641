import React, { createContext, useState, useContext } from 'react';

// Create a context for the global state
const GlobalStateContext = createContext();

// Create a provider component to wrap your app and provide the global state
export const GlobalStateProvider = ({ children }) => {
    const [credits, setCredits] = useState(0);
    const [transactionMode, setTransactionMode] = useState(false);
    return (
        <GlobalStateContext.Provider
            value={{
                credits,
                setCredits,
                transactionMode,
                setTransactionMode,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

// Custom hook to use the global state and its setter
export const useGlobalCredits = () => {
    return useContext(GlobalStateContext);
};
