import React, { useState, useEffect } from 'react';
import { getUrl } from './pdfDisplay/utils';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const STATUS_DONE = 'done';
const STATUS_PROCESSING = 'processing';
const STATUS_FAILED = 'failed';

const CrawlingStatus = ({ repoName, user, onComplete, batchId }) => {
    const [status, setStatus] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchStatus = async () => {
            if (!batchId) {
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch(
                    getUrl(
                        `http://${process.env.REACT_APP_FAST_API_HOST}:${
                            process.env.REACT_APP_FAST_API_PORT
                        }/crawl/queue_status?user=${encodeURIComponent(
                            user.email
                        )}&repoName=${encodeURIComponent(
                            repoName
                        )}&batch_id=${encodeURIComponent(batchId)}`
                    ),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: encodeURIComponent(user.token),
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch status');
                }

                const data = await response.json();
                setStatus(data.status || {});
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching status:', error);
                setIsLoading(false);
            }
        };

        const interval = setInterval(fetchStatus, 2000);
        fetchStatus();

        return () => clearInterval(interval);
    }, [repoName, user, batchId]);

    // Check if crawling is complete - only count successful completions
    const isComplete =
        Object.keys(status).length > 0 &&
        Object.values(status).every(
            (urlStatus) => urlStatus.status === STATUS_DONE
        );

    if (isLoading) {
        return (
            <div className='bg-white rounded-xl p-6 border border-gray-200 shadow-sm'>
                <div className='text-center'>Loading crawling status...</div>
            </div>
        );
    }

    if (isComplete) {
        onComplete();
        return null;
    }

    // If there's no status yet but we're not loading, show a waiting message
    if (Object.keys(status).length === 0) {
        onComplete();
        return null;
    }

    // Calculate overall progress
    const totalUrls = Object.keys(status).length;
    const completedUrls = Object.values(status).filter(
        (urlStatus) =>
            urlStatus.status === STATUS_DONE ||
            urlStatus.status === STATUS_FAILED
    ).length;
    const progress = Math.round((completedUrls / totalUrls) * 100);

    // Get currently processing URLs
    const processingUrls = Object.entries(status)
        .filter(([_, urlStatus]) => urlStatus.status === STATUS_PROCESSING)
        .map(([url]) => url);

    // Get failed URLs
    const failedUrls = Object.entries(status)
        .filter(([_, urlStatus]) => urlStatus.status === STATUS_FAILED)
        .map(([url, urlStatus]) => ({
            url,
            error: urlStatus.error,
        }));

    // Get recent activities
    const recentActivities = Object.entries(status)
        .filter(
            ([_, urlStatus]) =>
                urlStatus.status === STATUS_DONE ||
                urlStatus.status === STATUS_FAILED
        )
        .map(([url, urlStatus]) => ({
            url,
            status: urlStatus.status,
            error: urlStatus.error,
        }));

    return (
        <div className='bg-white rounded-xl p-6 border border-gray-200 shadow-sm'>
            <div className='mb-6'>
                <h3 className='text-xl font-semibold text-gray-900 mb-2'>
                    Repository Setup{' '}
                    {failedUrls.length > 0 ? 'Failed' : 'in Progress'}
                </h3>
                <p className='text-sm text-gray-600'>
                    {failedUrls.length > 0
                        ? 'There were issues processing some URLs.'
                        : 'Please wait while we crawl and process the URLs.'}
                </p>
            </div>

            <div className='mb-6'>
                <div className='flex justify-between mb-2'>
                    <span className='text-sm font-medium text-gray-700'>
                        Progress
                    </span>
                    <span className='text-sm font-medium text-gray-900'>
                        {progress}%
                    </span>
                </div>
                <div className='w-full bg-gray-200 rounded-full h-2.5'>
                    <div
                        className={`h-2.5 rounded-full transition-all duration-500 ${
                            failedUrls.length > 0 ? 'bg-red-600' : 'bg-blue-600'
                        }`}
                        style={{ width: `${progress}%` }}
                    />
                </div>
            </div>

            <div className='mb-6'>
                <div className='text-center p-3 bg-gray-50 rounded-lg'>
                    <div className='text-sm text-gray-600 mb-1'>
                        URLs Processed
                    </div>
                    <div className='text-lg font-semibold text-gray-900'>
                        {completedUrls}/{totalUrls}
                    </div>
                </div>
            </div>

            {failedUrls.length > 0 && (
                <div className='text-sm mb-6'>
                    <div className='text-red-600 font-medium mb-2'>
                        Failed URLs:
                    </div>
                    {failedUrls.map(({ url, error }) => (
                        <div key={url} className='mb-4'>
                            <div className='font-mono bg-red-50 p-3 rounded-lg break-all border border-red-200'>
                                {url}
                            </div>
                            <div className='text-red-600 mt-1 ml-3 text-sm'>
                                Error: {error}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {processingUrls.length > 0 && (
                <div className='text-sm mb-6'>
                    <div className='text-blue-600 mb-2 font-medium'>
                        Currently Processing:
                    </div>
                    {processingUrls.map((url) => (
                        <div
                            key={url}
                            className='text-gray-900 font-mono bg-blue-50 p-3 rounded-lg break-all border border-blue-100'
                        >
                            {url}
                        </div>
                    ))}
                </div>
            )}

            {recentActivities.length > 0 && (
                <div className='mt-6'>
                    <h4 className='text-lg font-semibold text-gray-900 mb-2'>
                        Recent Activity
                    </h4>
                    <div className='space-y-2'>
                        {recentActivities.map((activity, index) => (
                            <div
                                key={index}
                                className={`flex items-center p-3 rounded-lg ${
                                    activity.status === STATUS_DONE
                                        ? 'bg-green-50 border border-green-100'
                                        : 'bg-red-50 border border-red-100'
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        activity.status === STATUS_DONE
                                            ? faCheck
                                            : faTimes
                                    }
                                    className={`mr-3 ${
                                        activity.status === STATUS_DONE
                                            ? 'text-green-500'
                                            : 'text-red-500'
                                    }`}
                                />
                                <div className='flex-1'>
                                    <div className='flex items-center justify-between'>
                                        <div className='font-mono text-sm break-all'>
                                            {activity.url}
                                        </div>
                                        <div
                                            className={`ml-4 text-sm ${
                                                activity.status === STATUS_DONE
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                            }`}
                                        >
                                            {activity.status === STATUS_DONE
                                                ? 'Successfully crawled'
                                                : 'Failed'}
                                        </div>
                                    </div>
                                    {activity.status === STATUS_FAILED && (
                                        <div className='text-red-600 text-sm mt-2 ml-0'>
                                            {activity.error}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CrawlingStatus;
