export const SUBSCRIPTION_PLANS = {
    FREE: 'FREE',
    BASIC: 'BASIC',
    PRO: 'PRO',
    ADVANCED: 'ADVANCED',
    ENTERPRISE: 'ENTERPRISE',
};

export const PlanPriority = Object.freeze({
    FREE: 0,
    BASIC: 1,
    PRO: 2,
    ADVANCED: 3,
    ENTERPRISE: 7,
    BASIC_YEARLY: 4,
    PRO_YEARLY: 5,
    ADVANCED_YEARLY: 6,
});

export const YEARLY_DISCOUNT_PERCENTAGE = 17;

export const calculateYearlyPrice = (monthlyPrice, planName) => {
    if (planName === SUBSCRIPTION_PLANS.FREE) return 0;
    const yearlyDiscount = 0.17;
    return Math.round(monthlyPrice * 12 * (1 - yearlyDiscount));
};

export const getPlanPriority = (planName) => {
    if (!planName) return 0;
    return PlanPriority[planName.toUpperCase()] || 0;
};
