import {
    faAdd,
    faBriefcase,
    faCircleXmark,
    faClose,
    faCopy,
    faEdit,
    faExternalLink,
    faKey,
    faPencil,
    faShoppingCart,
    faTrash,
    faUser,
    faFont,
    faPalette,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import {
    Button,
    ConfirmModal,
    Dropdown,
    Input,
    Modal,
} from '../reusableComponents';
import { getUrl } from '../pdfDisplay/utils';
import axios from 'axios';
import { isEmailValid, isValidContactNumber } from '../../utils';
import { supportedThemes, ThemeViewer } from '../../themes';
import { supportedFonts } from '../../fonts/supportedFonts';
import { supportedLanguages } from '../../languages';
import Toggle from '../reusableComponents/Toggle';
import { toSentenceCase } from '../reportLayouts/utils';
import { defaultFont, defaultTheme } from '../../App';

const maxCompanyNameLength = 40;
const maxCopyRightTextLength = 40;

function isValidCompanyName(name) {
    const regex = /^[A-Za-z0-9 .,'&-]+$/;
    const minLength = 2;

    return (
        regex.test(name) &&
        name.length >= minLength &&
        name.length <= maxCompanyNameLength
    );
}
const isValidCopyRightText = (text) => {
    // const regex =
    //     /^(©|Copyright)\s*(\d{4})(-\d{4})?\s*([A-Za-z0-9 .,'"-]{0,40})?$/;
    // const minLength = 5;

    // return (
    //     regex.test(text) &&
    //     text.length >= minLength &&
    //     text.length <= maxCopyRightTextLength
    // );
    return true;
};

export const validateVendorsData = (vendorData) => {
    const result = { isValid: true, error: '' };
    if (!vendorData.company_name) {
        result.isValid = false;
        result.error = 'Company Name is required in SUPPORT SETTINGS';
        return result;
    }
    if (!isValidCompanyName(vendorData.company_name)) {
        result.isValid = false;
        result.error = 'Please provide valid Company Name in SUPPORT SETTINGS';
        return result;
    }

    if (!vendorData.contact_email) {
        result.isValid = false;
        result.error = 'Contact Email is required in SUPPORT SETTINGS';
        return result;
    }
    if (!isEmailValid(vendorData.contact_email)) {
        result.isValid = false;
        result.error = 'Please provide valid Contact Email in SUPPORT SETTINGS';
        return result;
    }

    if (!vendorData.contact_number) {
        result.isValid = false;
        result.error = 'Contact Number is required in SUPPORT SETTINGS';
        return result;
    }
    if (!isValidContactNumber(vendorData.contact_number)) {
        result.isValid = false;
        result.error =
            'Please provide valid Contact Number in SUPPORT SETTINGS';
        return result;
    }

    if (vendorData.copy_right_text) {
        if (!isValidCopyRightText(vendorData.copy_right_text)) {
            result.isValid = false;
            result.error =
                'Please provide valid Copy Rights Text in SUPPORT SETTINGS';
            return result;
        }
    }

    return result;
};

// Handle file selection
const handleFileChange = (event, accountDetails, setAccountDetails) => {
    const file = event.target.files[0];
    if (file) {
        handleVendorFieldUpdate(
            'logo',
            file,
            accountDetails,
            setAccountDetails
        );
    }
};

// Trigger the hidden file input when the custom button is clicked
const handleButtonClick = (fileInputRef) => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
};

const LogoComponent = ({
    isSmallScreen,
    accountDetails,
    setAccountDetails,
}) => {
    const fileInputRef = useRef(null); // Reference to the hidden file input
    const logo = accountDetails.vendorDetails
        ? accountDetails.vendorDetails.logo
        : null;

    return (
        <div className='flex items-center'>
            {/* Show the existing logo*/}
            <div
                className='relative rounded-xl border flex items-center justify-center bg-backgrounds-white p-2'
                style={{ width: '100px', height: '100px' }}
            >
                {logo ? (
                    <img
                        src={URL.createObjectURL(new Blob([logo]))}
                        alt='Current Logo'
                        className='rounded-xl'
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faBriefcase}
                        size='3x'
                        className='text-texts-secondary300 rounded-xl p-6 bg-backgrounds-white'
                    />
                )}

                {/* Hidden file input */}
                <input
                    type='file'
                    accept='image/*'
                    ref={fileInputRef} // Use the ref to access the input
                    style={{ display: 'none' }} // Hide the input element
                    onChange={(event) =>
                        handleFileChange(
                            event,
                            accountDetails,
                            setAccountDetails
                        )
                    } // Handle file selection
                />
            </div>

            <div className='flex flex-col justify-between items-center px-4'>
                <Button
                    className={`my-2 ${isSmallScreen ? '' : 'w-28'}`}
                    hoverText={logo ? 'Change this logo' : 'Add new logo'}
                    text={isSmallScreen ? null : logo ? 'Edit' : 'Add'}
                    icon={logo ? faPencil : faAdd}
                    type={'success'}
                    onClick={() => handleButtonClick(fileInputRef)}
                />

                {logo && (
                    <Button
                        className={`my-2  ${isSmallScreen ? '' : 'w-28'}`}
                        hoverText={'Remove this logo'}
                        text={isSmallScreen ? null : 'Remove'}
                        icon={faClose}
                        type={'danger'}
                        onClick={() =>
                            handleVendorFieldUpdate(
                                'logo',
                                null,
                                accountDetails,
                                setAccountDetails
                            )
                        }
                    />
                )}
            </div>
        </div>
    );
};

const handleCopy = async (textData, openNotification) => {
    try {
        // Ensure permission query is executed before copying
        await navigator.permissions.query({ name: 'clipboard-write' });
        await navigator.clipboard.writeText(textData);

        const notificationDuration = 1.5; // sec
        const placement = 'top';
        const notification = 'copied!';

        openNotification(placement, notification, null, notificationDuration);
    } catch (err) {
        alert('Failed to copy text: ', err);
    }
};

const handleUpgrade = (accountDetails, setAccountDetails) => {
    const vendorDetails = {
        logo: null,
        company_name: null,
        contact_email: null,
        contact_number: null,
        company_domain: null,
        vendor_id: null,
        copy_right_text: null,
        language: supportedLanguages.ENGLISH_US,
        allow_language_selection: true,
        theme: defaultTheme,
        font: defaultFont,
        allow_audio_feature: false,
    };
    const accountData = { ...accountDetails, vendorDetails: vendorDetails };
    setAccountDetails(accountData);
};

const handleDelete = async (user, accountDetails, setAccountDetails) => {
    try {
        const dynamicUrl = getUrl(
            `http://${process.env.REACT_APP_FAST_API_HOST}:${process.env.REACT_APP_FAST_API_PORT}/customer_chat_routes/remove-vendor`
        );
        const encodedToken = encodeURIComponent(user.token);

        // Create FormData to send the token
        const formData = new FormData();
        formData.append('token', user.token);

        // Send the DELETE request with the FormData as the second argument
        await axios.delete(dynamicUrl, {
            data: formData,
            headers: {
                Authorization: encodedToken,
            },
        });

        const accountData = { ...accountDetails };
        delete accountData['vendorDetails'];
        setAccountDetails(accountData);
    } catch (error) {
        console.error('Error removing vendor:', error); // Add error logging for debugging
    }
};

const handleVendorFieldUpdate = (
    fieldName,
    value,
    accountDetails,
    setAccountDetails
) => {
    const accountData = { ...accountDetails };
    accountData.vendorDetails[fieldName] = value;
    setAccountDetails(accountData);
};

const validatePassword = (password) => {
    const minLength = 8;
    const isValid =
        password &&
        password.length >= minLength &&
        /[A-Z]/.test(password) && // Uppercase letter
        /[a-z]/.test(password) && // Lowercase letter
        /\d/.test(password) && // Digit
        /[!@#$%^&*(),.?":{}|<>]/.test(password); // Special character

    return isValid
        ? ''
        : 'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a digit, and a special character.';
};

const supportedThemeColors = Object.keys(supportedThemes);
const supportedFontKeys = Object.keys(supportedFonts);
const supportedLanguageKeys = Object.keys(supportedLanguages);
const reverseLanguageMap = {};
supportedLanguageKeys.forEach(
    (key) => (reverseLanguageMap[supportedLanguages[key]] = key)
);

export const AccountSettingsComponent = ({
    user,
    accountDetails,
    setAccountDetails,
    SettingSections,
    isSmallScreen,
}) => {
    const { personalDetails, vendorDetails } = accountDetails;
    const [viewDeleteConfirmModal, setViewDeleteConfirmModal] = useState(false);
    const [encodedUrl, setEncodedUrl] = useState(null);
    const [api, contextHolder] = notification.useNotification();
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState(null);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [languageDropdown, setLanguageDropdown] = useState([]);
    const [themeDropdown, setThemeDropdown] = useState([]);
    const [fontDropdown, setFontDropdown] = useState([]);

    const openNotification = (placement, text, description, duration) => {
        api.success({
            message: text,
            description: description,
            placement,
            duration,
        });
    };

    useEffect(() => {
        if (accountDetails.vendorDetails) {
            const themeDropDown = supportedThemeColors.map((color) => {
                const data = {
                    key: color,
                    label: (
                        <ThemeViewer
                            supportedThemes={supportedThemes}
                            theme={color}
                        />
                    ),

                    icon: null,
                    iconColor: null,
                    selected: color === accountDetails.vendorDetails.theme,
                    action: () => {
                        handleVendorFieldUpdate(
                            'theme',
                            color,
                            accountDetails,
                            setAccountDetails
                        );
                    },
                };
                return data;
            });

            setThemeDropdown(themeDropDown);
        }
    }, [accountDetails.vendorDetails?.theme]);

    useEffect(() => {
        if (accountDetails.vendorDetails) {
            const languageDropDown = supportedLanguageKeys.map((lang) => {
                const data = {
                    key: lang,
                    label: toSentenceCase(lang),

                    icon: null,
                    iconColor: null,
                    selected: lang === accountDetails.vendorDetails.language,
                    action: () => {
                        handleVendorFieldUpdate(
                            'language',
                            supportedLanguages[lang],
                            accountDetails,
                            setAccountDetails
                        );
                    },
                };
                return data;
            });

            setLanguageDropdown(languageDropDown);
        }
    }, [accountDetails.vendorDetails?.language]);

    useEffect(() => {
        if (accountDetails.vendorDetails) {
            const fontDropdown = supportedFontKeys.map((fontKey) => {
                const data = {
                    key: fontKey,
                    label: (
                        <div className={`font-${fontKey}`}>
                            {toSentenceCase(fontKey)}
                        </div>
                    ),

                    icon: null,
                    iconColor: null,
                    selected: fontKey === accountDetails.vendorDetails.font,
                    action: () => {
                        handleVendorFieldUpdate(
                            'font',
                            fontKey,
                            accountDetails,
                            setAccountDetails
                        );
                    },
                };
                return data;
            });

            setFontDropdown(fontDropdown);
        }
    }, [accountDetails.vendorDetails?.font]);

    useEffect(() => {
        if (
            accountDetails.vendorDetails &&
            accountDetails.vendorDetails.vendor_id
        ) {
            // const encodedId = compressAndEncodeJWT(
            //     accountDetails.vendorDetails.vendor_id
            // );

            const encodedId = accountDetails.vendorDetails.vendor_id;
            const enableAudioFeature =
                accountDetails.vendorDetails.allow_audio_feature;
            const url = `${
                process.env.REACT_APP_PRODUCT_SUPPORT_DOMAIN
            }/product-support/${encodedId}${
                enableAudioFeature ? '?audio=true' : ''
            }`;
            setEncodedUrl(url);
        } else {
            setEncodedUrl(null);
        }

        return () => {};
    }, [accountDetails, accountDetails.vendorDetails?.allow_audio_feature]);

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            // Display mismatch error
            alert('New Password and Confirm Password do not match.');
            return;
        }

        const validationError = validatePassword(newPassword);
        if (validationError) {
            // Display validation error
            alert(validationError);
            return;
        }

        try {
            const dynamicUrl = getUrl(
                `http://${process.env.REACT_APP_NODE_SERVER_HOST}:${process.env.REACT_APP_NODE_SERVER_PORT}/update-password`
            );
            // Encode user token
            const encodedToken = encodeURIComponent(user.token);

            const requestBody = {
                email: user.email,
                newPassword: newPassword,
            };

            // Make the API call
            const response = await axios.post(dynamicUrl, requestBody, {
                headers: {
                    Authorization: encodedToken,
                },
            });

            // Handle API response
            if (response.data.message === 'Password updated successfully!') {
                setIsPasswordModalOpen(false);
                alert('Password updated successfully!');
            } else if (
                response.data.message ===
                'New password cannot be the same as the old password'
            ) {
                alert(
                    'New password cannot be the same as the previous password.'
                );
            } else {
                alert('Failed to update password. Please try again.');
            }
        } catch (error) {
            console.error('Error updating password:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className='mb-6'>
            {contextHolder}
            {viewDeleteConfirmModal && (
                <ConfirmModal
                    modalText={
                        'Delete this product support account and remove all file and product mappings related to this account?'
                    }
                    onCancel={() => setViewDeleteConfirmModal(false)}
                    onConfirm={() => {
                        handleDelete(user, accountDetails, setAccountDetails);
                        setViewDeleteConfirmModal(false);
                    }}
                />
            )}

            <div
                className={`flex items-center justify-between font-semibold mb-6 ${
                    !vendorDetails ? 'py-2' : 'py-3'
                }  px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate`}
            >
                <div className='flex items-center'>
                    <FontAwesomeIcon icon={faUser} className='mr-2' />
                    <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                        {SettingSections.ACCOUNT}
                    </div>
                </div>

                {(!vendorDetails ||
                    (vendorDetails &&
                        Object.keys(vendorDetails).length === 0)) && (
                    <Button
                        className={`ml-2`}
                        hoverText={'Upgrade to product support account'}
                        text={'Upgrade'}
                        icon={faShoppingCart}
                        type={'success'}
                        onClick={() =>
                            handleUpgrade(accountDetails, setAccountDetails)
                        }
                    />
                )}
            </div>

            <div className='px-6 relative'>
                <div className='flex items-center mb-6'>
                    <div className='w-1/4 font-semibold text-texts-secondary300'>
                        <label htmlFor='userName'>Name</label>
                    </div>

                    <div className='w-full md:w-1/4'>
                        <div className='relative'>
                            <Input
                                type={'text'}
                                value={
                                    personalDetails && personalDetails.username
                                        ? personalDetails.username
                                        : ''
                                }
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center mb-6'>
                    <div className='w-1/4 font-semibold text-texts-secondary300'>
                        <label htmlFor='email'>Email</label>
                    </div>

                    <div className='w-full md:w-1/4'>
                        <div className='relative'>
                            <Input
                                type={'text'}
                                value={
                                    personalDetails && personalDetails.email
                                        ? personalDetails.email
                                        : ''
                                }
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center mb-8'>
                    <div
                        className={`w-1/4 font-semibold text-texts-secondary300 ${
                            isSmallScreen ? 'pr-2' : ''
                        }`}
                    >
                        <label htmlFor='currency'>Currency</label>
                    </div>

                    <div className='w-full md:w-1/4'>
                        <div className='relative'>
                            <Input
                                type={'text'}
                                value={
                                    personalDetails && personalDetails.currency
                                        ? personalDetails.currency
                                        : ''
                                }
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center mb-8'>
                    <div
                        className={`w-1/4 font-semibold text-texts-secondary300 ${
                            isSmallScreen ? 'pr-2' : ''
                        }`}
                    >
                        <label htmlFor='password'>Password</label>
                    </div>
                    <Button
                        text={'Change Password'}
                        icon={faKey}
                        type={'success'}
                        hoverText={'Change password.'}
                        onClick={() => setIsPasswordModalOpen(true)}
                    />
                </div>
                {/*Change Password Modal*/}
                {isPasswordModalOpen && (
                    <Modal
                        title={'Change Password'}
                        titleIcon={faKey}
                        iconColor={'lightblue'}
                        onClose={() => setIsPasswordModalOpen(false)}
                    >
                        <div className='w-[90vw] min-w-[90px] md:w-[60vw] md:min-w-[500px] flex-col px-6 py-6 rounded-xl bg-backgrounds-slate'>
                            <div className='relative flex flex-col w-full text-texts-secondary300'>
                                {/** Define a common class for the input rows for better alignment */}
                                <div className='flex items-center mb-4'>
                                    <label className='w-1/4 font-semibold text-texts-secondary300'>
                                        New Password
                                    </label>
                                    <Input
                                        maxLength={20}
                                        hoverText={'Type in your New Password'}
                                        type={
                                            newPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        className='flex-1 p-3 border rounded-xl bg-backgrounds-slate hover:border-backgrounds-primary300 focus:outline-backgrounds-primary200 '
                                        placeholder='New Password'
                                        value={newPassword}
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                    />
                                    <button
                                        onClick={() =>
                                            setNewPasswordVisible(
                                                !newPasswordVisible
                                            )
                                        }
                                        className='ml-2 text-backgrounds-primary400'
                                    >
                                        {newPasswordVisible ? 'Hide' : 'Show'}
                                    </button>
                                </div>

                                <div className='flex items-center mb-4'>
                                    <label className='w-1/4 font-semibold text-texts-secondary300'>
                                        Confirm Password
                                    </label>
                                    <Input
                                        maxLength={20}
                                        hoverText={'Verify your New Password'}
                                        type={
                                            confirmPasswordVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        className='flex-1 p-3 border rounded-xl bg-backgrounds-slate hover:border-backgrounds-primary300 focus:outline-backgrounds-primary200 '
                                        placeholder='Confirm New Password'
                                        value={confirmPassword}
                                        onChange={(e) =>
                                            setConfirmPassword(e.target.value)
                                        }
                                    />
                                    <button
                                        onClick={() =>
                                            setConfirmPasswordVisible(
                                                !confirmPasswordVisible
                                            )
                                        }
                                        className='ml-2 text-backgrounds-primary400'
                                    >
                                        {confirmPasswordVisible
                                            ? 'Hide'
                                            : 'Show'}
                                    </button>
                                </div>

                                <div className='flex items-center justify-center mt-4'>
                                    <div className='flex items-center justify-between w-full'>
                                        <Button
                                            type='danger'
                                            icon={faCircleXmark}
                                            text={'Cancel'}
                                            title={'Cancel and close the modal'}
                                            className='w-1/3'
                                            onClick={() =>
                                                setIsPasswordModalOpen(false)
                                            }
                                        />

                                        <Button
                                            type='success'
                                            icon={faEdit}
                                            text={'Update'}
                                            className={
                                                'flex items-center justify-center rounded-full py-1.5 px-2.5 rounded-lg order bg-backgrounds-white shadow-inner border font-semibold w-1/3 hover:bg-backgrounds-primary100 hover:border-backgrounds-primary200 text-backgrounds-primary400'
                                            }
                                            title='Update your password.'
                                            onClick={handlePasswordChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                {/* Vendor Settings Section*/}
                {vendorDetails && Object.keys(vendorDetails).length !== 0 && (
                    <div
                        className={`${
                            isSmallScreen ? '' : 'border rounded-xl'
                        } mb-6`}
                    >
                        <div
                            className={`flex items-center justify-between font-semibold mb-6 py-2 px-4 text-texts-secondary300 uppercase bg-backgrounds-primary100 truncate ${
                                isSmallScreen ? '' : 'rounded-t-xl'
                            }`}
                        >
                            <div className='flex items-center'>
                                <FontAwesomeIcon
                                    icon={faShoppingCart}
                                    className='mr-2'
                                />
                                <div className='font-semibold text-texts-secondary300 uppercase truncate'>
                                    Support Settings
                                </div>
                            </div>

                            <div>
                                <Button
                                    className={`ml-2`}
                                    hoverText={
                                        'Delete this Product support account'
                                    }
                                    text={'Delete'}
                                    icon={faTrash}
                                    type={'danger'}
                                    onClick={() =>
                                        setViewDeleteConfirmModal(true)
                                    }
                                />
                            </div>
                        </div>

                        <div className={isSmallScreen ? '' : 'px-6'}>
                            {/* Logo */}
                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='companyName'>
                                        Company Logo
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <LogoComponent
                                            isSmallScreen={isSmallScreen}
                                            accountDetails={accountDetails}
                                            setAccountDetails={
                                                setAccountDetails
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='companyName'>
                                        Company Name
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.company_name
                                                    ? vendorDetails.company_name
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'company_name',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            placeholder={'Required'}
                                            maxLength={maxCompanyNameLength}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='contactEmail'>
                                        Contact Email
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.contact_email
                                                    ? vendorDetails.contact_email
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'contact_email',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            placeholder={'Required'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='contactNumber'>
                                        Contact Number
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.contact_number
                                                    ? vendorDetails.contact_number
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'contact_number',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            placeholder={'Required'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='companyDomain'>
                                        Company Domain
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.company_domain
                                                    ? vendorDetails.company_domain
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'company_domain',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='copyRightText'>
                                        Copy Rights Text
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative'>
                                        <Input
                                            type={'text'}
                                            value={
                                                vendorDetails &&
                                                vendorDetails.copy_right_text
                                                    ? vendorDetails.copy_right_text
                                                    : ''
                                            }
                                            onChange={(e) =>
                                                handleVendorFieldUpdate(
                                                    'copy_right_text',
                                                    e.target.value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            maxLength={maxCopyRightTextLength}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Audio Feature */}
                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='allowAudioFeature'>
                                        Audio Feature
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4 flex'>
                                    <Toggle
                                        checked={
                                            vendorDetails?.allow_audio_feature ??
                                            true
                                        }
                                        onChange={(value) =>
                                            handleVendorFieldUpdate(
                                                'allow_audio_feature',
                                                value,
                                                accountDetails,
                                                setAccountDetails
                                            )
                                        }
                                        toggleTextClassName={''}
                                    />
                                </div>
                            </div>

                            {/* Language */}
                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='defaultLanguage'>
                                        Language
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative w-full'>
                                        <Dropdown
                                            dropDownList={languageDropdown}
                                            showButtonText={true}
                                            buttonText={toSentenceCase(
                                                reverseLanguageMap[
                                                    accountDetails
                                                        ?.vendorDetails
                                                        ?.language
                                                ]
                                            )}
                                            titleText={null}
                                            buttonHoverText={'Select Language'}
                                            buttonClassName={
                                                'text-texts-tertiary400 mr-1 h-8 w-64'
                                            }
                                            listClassName={''}
                                            listTitleText={'Languages'}
                                            disabled={false}
                                            placement={'right'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='allowLanguageSelection'>
                                        Allow Users to Change Language
                                    </label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative w-full'>
                                        <Toggle
                                            checked={
                                                vendorDetails?.allow_language_selection ??
                                                true
                                            }
                                            onChange={(value) =>
                                                handleVendorFieldUpdate(
                                                    'allow_language_selection',
                                                    value,
                                                    accountDetails,
                                                    setAccountDetails
                                                )
                                            }
                                            toggleTextClassName={''}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Fonts */}
                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='copyRightText'>Font</label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative w-full'>
                                        <Dropdown
                                            dropDownList={fontDropdown}
                                            showButtonText={true}
                                            buttonText={
                                                <div
                                                    className={`font-${accountDetails?.vendorDetails?.font}`}
                                                >
                                                    {toSentenceCase(
                                                        accountDetails
                                                            ?.vendorDetails
                                                            ?.font
                                                    )}
                                                </div>
                                            }
                                            titleText={null}
                                            buttonHoverText={'Select Font'}
                                            buttonClassName={
                                                'text-texts-tertiary400 mr-1 h-8 w-64'
                                            }
                                            listClassName={''}
                                            listTitleText={
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faFont}
                                                        className='mr-2 text-texts-secondary300'
                                                    />
                                                    <span>Fonts</span>
                                                </>
                                            }
                                            disabled={false}
                                            placement={'right'}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Theme */}
                            <div className='flex items-center mb-6'>
                                <div className='w-2/4 font-semibold text-texts-secondary300'>
                                    <label htmlFor='copyRightText'>Theme</label>
                                </div>

                                <div className='w-full md:w-1/4'>
                                    <div className='relative w-full'>
                                        <Dropdown
                                            dropDownList={themeDropdown}
                                            showButtonText={true}
                                            buttonText={
                                                <ThemeViewer
                                                    supportedThemes={
                                                        supportedThemes
                                                    }
                                                    theme={
                                                        accountDetails
                                                            ?.vendorDetails
                                                            ?.theme
                                                    }
                                                />
                                            }
                                            titleText={null}
                                            buttonHoverText={'Select Theme'}
                                            buttonClassName={
                                                'text-texts-tertiary400 mr-1 h-8 w-64'
                                            }
                                            listClassName={''}
                                            listTitleText={
                                                <>
                                                    <FontAwesomeIcon
                                                        icon={faPalette}
                                                        className='mr-2 text-texts-secondary300'
                                                    />
                                                    <span>Themes</span>
                                                </>
                                            }
                                            disabled={false}
                                            placement={'right'}
                                        />
                                    </div>
                                </div>
                            </div>

                            {vendorDetails && vendorDetails.vendor_id && (
                                <div className='flex items-center mb-6'>
                                    <div className='w-2/4 font-semibold text-texts-secondary300'>
                                        <label htmlFor='copyRightText'>
                                            Support Link
                                        </label>
                                    </div>

                                    <div className='w-full md:w-1/2'>
                                        <div className='relative flex items-center'>
                                            <div className='flex items-center relative w-1/2'>
                                                <Input
                                                    type={'text'}
                                                    value={
                                                        process.env
                                                            .REACT_APP_PRODUCT_SUPPORT_DOMAIN
                                                            ? encodedUrl
                                                            : ''
                                                    }
                                                    disabled={true}
                                                />
                                                <div className='absolute right-2'>
                                                    <Button
                                                        className={`ml-2`}
                                                        hoverText={'Copy link'}
                                                        text={null}
                                                        icon={faCopy}
                                                        type={'success'}
                                                        onClick={async () =>
                                                            await handleCopy(
                                                                encodedUrl,
                                                                openNotification
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <Button
                                                className={`ml-3`}
                                                hoverText={'Open link'}
                                                text={'Open'}
                                                icon={faExternalLink}
                                                type={'success'}
                                                onClick={() =>
                                                    window.open(
                                                        encodedUrl,
                                                        '_blank'
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
