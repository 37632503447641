import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { getUrl } from './pdfDisplay/utils';
export const CrawlHistoryTable = ({ repoId }) => {
    const [crawlHistory, setCrawlHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const getCrawlHistory = async () => {
        try {
            const response = await fetch(
                getUrl(
                    `http://${process.env.REACT_APP_FAST_API_HOST}:${
                        process.env.REACT_APP_FAST_API_PORT
                    }/crawl/crawl_timestamps?repo_id=${encodeURIComponent(
                        repoId
                    )}`
                )
            );

            const history = await response.json();
            setCrawlHistory(history);
        } catch (error) {
            console.error('Error fetching crawl history:', error);
            setErrorMessage('Failed to fetch crawl history.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCrawlHistory();
    }, [repoId]);

    const columns = [
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: 'Last Crawled',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => new Date(text).toLocaleString(),
        },
    ];

    return (
        <div>
            {isLoading ? (
                <div>Loading...</div>
            ) : errorMessage ? (
                <div>{errorMessage}</div>
            ) : (
                <Table
                    dataSource={crawlHistory.map((entry, index) => ({
                        key: index,
                        url: entry.url,
                        timestamp: entry.last_crawled_at,
                    }))}
                    columns={columns}
                />
            )}
        </div>
    );
};
