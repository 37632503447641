export const supportedThemes = Object.freeze({
    default: 'rgb(9, 88, 217)', // Default blue
    blue: 'rgb(56, 189, 248)', // True blue
    gold: 'rgb(202, 138, 4)', // Gold
    green: 'rgb(5, 150, 105)', // Green
    orange: 'rgb(234, 88, 12)', // Dark orange
    purple: 'rgb(107, 33, 168)', // Purple
    red: 'rgb(220, 38, 38)', // Red
    turquoise: 'rgb(20, 184, 166)', // Turquoise
    violet: 'rgb(124, 58, 237)', // Violet
});
