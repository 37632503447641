import { FILE_EXTENSIONS } from '../constants/fileExtensions';

export const isMarkdownFile = (fileExtension) => {
    if (!fileExtension) {
        return false;
    }

    const extension = fileExtension.toLowerCase();
    return (
        extension === FILE_EXTENSIONS.MD ||
        extension === FILE_EXTENSIONS.MARKDOWN
    );
};
